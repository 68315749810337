import { createAsyncThunk } from '@reduxjs/toolkit'
import serviceRequest from '~/app/serviceRequest'
import billApi from '~/api/billApi'

export const getExpenseYearly = createAsyncThunk(
    'bill/getexpenseyearly',
    async (data: unknown | undefined, thunkAPI) => {
        return serviceRequest({
            dispatch: thunkAPI.dispatch,
            serviceMethod: billApi.getExpenseYearly,
            payload: data,
            options: {
                skipLoader: false,
            },
        })
    },
)

export const getExpenseTableYearly = createAsyncThunk(
    'bill/getexpensetableyearly',
    async (data: unknown | undefined, thunkAPI) => {
        return serviceRequest({
            dispatch: thunkAPI.dispatch,
            serviceMethod: billApi.getExpenseTableYearly,
            payload: data,
            options: {
                skipLoader: false,
            },
        })
    },
)

export const getExpenseCodeTableYearly = createAsyncThunk(
    'bill/getexpensecodetableyearly',
    async (data: unknown | undefined, thunkAPI) => {
        return serviceRequest({
            dispatch: thunkAPI.dispatch,
            serviceMethod: billApi.getExpenseCodeTableYearly,
            payload: data,
            options: {
                skipLoader: false,
            },
        })
    },
)

export const getExpenseCodeDetail = createAsyncThunk(
    'bill/getexpensecodedetail',
    async (data: unknown | undefined, thunkAPI) => {
        return serviceRequest({
            dispatch: thunkAPI.dispatch,
            serviceMethod: billApi.getExpenseCodeDetail,
            payload: data,
            options: {
                skipLoader: false,
            },
        })
    },
)

export const getExpenseMonthlyPaymentMethod = createAsyncThunk(
    'bill/getexpensemonthlypaymentmethod',
    async (data: unknown | undefined, thunkAPI) => {
        return serviceRequest({
            dispatch: thunkAPI.dispatch,
            serviceMethod: billApi.getExpenseMonthlyPaymentMethod,
            payload: data,
            options: {
                skipLoader: false,
            },
        })
    },
)

