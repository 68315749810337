/* eslint-disable no-useless-escape */
import { GridColDef } from '@mui/x-data-grid'

const autoCompleteType = {
  event: {
    selectOption: 'selectOption',
    removeOption: 'removeOption',
    clear: 'clear',
  },
}

const defaultId = '00000000-0000-0000-0000-000000000000'

const paymentStatus = {
  paid: 'Paid',
  unpaid: 'Unpaid',
}

const defaultProfile = ['/:id', '/:id/edit']

const roleInspectorAccept = [
  ...defaultProfile,
  '/order',
  '/edit-order',
  '/order/:id',
  '/audit',
  '/audit/:id',
  '/allowance',
  '/allowance/:id',
]

const PAYMENT_STATUS = [
  {
    id: '8be9be04-a90d-4aca-b285-983f62c70d77',
    status: 'Unpaid',
    backgroundColor: '#a42227',
  },
  {
    id: '98c7de80-5a4e-40e5-a0f8-9971c6a51bd3',
    status: 'Paid',
    backgroundColor: '#29436e',
  },
]

const inspectionProjectLevels = ['N/A', 'S-1', 'S-2', 'S-3', 'S-4', 'Level-I', 'Level-II', 'Level-III', 'Fixed']

const orderColumns: GridColDef[] = [
  {
    field: 'number',
    headerName: 'No.',
    flex: 0.2,
    headerAlign: 'center',
    align: 'center',
    renderCell: params => params.api.getAllRowIds().indexOf(params.id) + 1,
  },
  {
    field: 'projectNo',
    headerName: 'Project No./ PO No.',
    flex: 1,
    headerAlign: 'center',
    align: 'center',
    editable: true,
  },
  {
    field: 'item',
    headerName: 'Item/ Ref No.',
    flex: 1,
    headerAlign: 'center',
    align: 'center',
    editable: true,
  },
  {
    field: 'productDescription',
    headerName: 'Product Description',
    flex: 1.5,
    headerAlign: 'center',
    align: 'center',
    editable: true,
  },
  {
    field: 'productQuantity',
    headerName: 'Quantity',
    flex: 0.5,
    headerAlign: 'center',
    align: 'center',
    editable: true,
  },
  {
    field: 'inspectionLevel',
    headerName: 'Inspection Level',
    flex: 0.85,
    editable: true,
    type: 'singleSelect',
    valueOptions: inspectionProjectLevels,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'sampleSize',
    headerName: 'Sample size',
    headerAlign: 'center',
    align: 'center',
    editable: true,
  },
  {
    field: 'critical',
    headerName: 'Critical',
    type: 'number',
    flex: 0.5,
    headerAlign: 'center',
    align: 'center',
    editable: true,
  },
  {
    field: 'major',
    headerName: 'Major',
    type: 'number',
    flex: 0.5,
    headerAlign: 'center',
    align: 'center',
    editable: true,
  },
  {
    field: 'minor',
    headerName: 'Minor',
    type: 'number',
    flex: 0.5,
    headerAlign: 'center',
    align: 'center',
    editable: true,
  },
]

const auditColumns: GridColDef[] = [
  {
    field: 'number',
    headerName: 'No.',
    flex: 0.2,
    editable: false,
    headerAlign: 'center',
    align: 'center',
    renderCell: params => params.api.getAllRowIds().indexOf(params.id) + 1,
  },
  {
    field: 'projectNo',
    headerName: 'Project No./ PO No.',
    flex: 1,
    headerAlign: 'center',
    align: 'center',
    editable: true,
  },
  {
    field: 'productDescription',
    headerName: 'Product Description',
    flex: 1,
    headerAlign: 'center',
    align: 'center',
    editable: true,
  },
  {
    field: 'otherInformation',
    headerName: 'Other information',
    flex: 1,
    headerAlign: 'center',
    align: 'center',
    editable: true,
  },
]

const ROLES = {
  admin: 'Admin',
  keyStaff: 'Key Staff',
  officeStaff: 'Office Staff',
  inspector: 'Inspector',
}

const constant = {
  autoCompleteType,
  roleInspectorAccept,
  paymentStatus,
  PAYMENT_STATUS,
  orderColumns,
  auditColumns,
  ROLES,
  emailReg: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/,
  defaultId,
}

export { constant }
