// Core
import { FC } from 'react'
import { Navigate } from 'react-router-dom'

// App
import { RouteGuardProps } from './RouteGuard.type'
import { constant } from '~/constant'

const RouteGuard: FC<RouteGuardProps> = ({ route }) => {
  const isAuthenticated = localStorage.getItem('userData')
  const role = localStorage.getItem('ROLE')

  if (isAuthenticated && route.path === '/') {
    return <Navigate to='/overview' replace />
  }

  if (role !== 'Admin' && (route.path.includes('/bill') || route.path.includes('/expense'))) {
    return <Navigate to='/' replace />
  }

  if (route.public || !!isAuthenticated) {
    switch (role) {
      case 'Inspector':
        if (!constant.roleInspectorAccept.includes(route.path)) {
          return <Navigate to='/order' replace />
        }
        return (
          <route.layout>
            <route.component />
          </route.layout>
        )
      case 'Key Staff':
      case 'Office Staff':
        if (route.path === '/revenue') {
          return <Navigate to='/overview' replace />
        }
        return (
          <route.layout>
            <route.component />
          </route.layout>
        )
      default:
        return (
          <route.layout>
            <route.component />
          </route.layout>
        )
    }
  }

  return (
    <Navigate
      to='/'
      state={{
        from: route.path,
      }}
      replace
    />
  )
}

export default RouteGuard
