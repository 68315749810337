import axiosClient from './axiosClient'
import { payloadGetOrdersAllFilter } from './types/order'
import { payloadPage } from './types/paramPage'

const orderApi = {
  getOrders: (params: payloadPage) => {
    const url = '/orders/getall'
    return axiosClient.get(url, { params })
  },
  getOrdersByUserId: (params: payloadPage) => {
    const url = '/orders/getallbyuserid'
    return axiosClient.get(url, { params })
  },

  getOrdersAllFilter: (params: payloadGetOrdersAllFilter) => {
    const url = '/orders/getallfilter'
    return axiosClient.get(url, { params })
  },

  createOrder: params => {
    const url = '/orders/createorder'
    return axiosClient.post(url, params, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  },
  updateOrder: params => {
    const url = `/orders/updateorder/${params.id}`
    return axiosClient.put(url, params.body, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  },
  unlockOrder: (id: string) => {
    const url = `/orders/unlockorder/${id}`
    return axiosClient.put(url, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  },
  deleteOrder: (id: string) => {
    const url = `/orders/deleteorder/${id}`
    return axiosClient.delete(url)
  },
  copyToDraft: (id: string) => {
    const url = `/orders/copytodraft?orderId=${id}`
    return axiosClient.post(url)
  },
  getOrderById: (id: string) => {
    const url = `/orders/getorderbyid/${id}`
    return axiosClient.get(url)
  },
  updateStatus: (data: any) => {
    const url = `/orders/updatestatus`
    return axiosClient.put(url, data)
  },
  updateStatusFromDraftToUnfinish: (data: any) => {
    const url = `/orders/updatestatusfromdrafttounfinish`
    return axiosClient.put(url, data)
  },
  getFinishedOrders: () => {
    const url = `/orders/finished-orders`
    return axiosClient.get(url)
  },
  getInspectionStatus: () => {
    const url = `/inspectioninfo/getstatus`
    return axiosClient.get(url)
  },
}

export default orderApi
