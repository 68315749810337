import axiosClient from './axiosClient'
import { PayloadGetMonthlyExpenseTable } from './types/bill'

const billApi = {
    getBillById: (id: string) => {
        const url = `/bill/getbillbyid/${id}`
        return axiosClient.get(url)
    },
    createBill: params => {
        const url = '/bill/createbill'
        return axiosClient.post(url, params, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
    },
    updateBill: params => {
        const url = `/bill/updatebill/${params.id}`
        return axiosClient.put(url, params.body, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
    },
    deleteBill: (id: string) => {
        const url = `/bill/deletebill/${id}`
        return axiosClient.delete(url)
    },

    // Attachments
    uploadBillAttachment: (data: attachment) => {
        const url = `/finalreport/uploadbillattachment/${data.id}`
        return axiosClient.post(url, data.file, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
    },
    deleteBillAttachment: (id: string) => {
        const url = `/finalreport/delete/${id}`
        return axiosClient.delete(url)
    },
    downloadBillAttachment: (id: string) => {
        const url = `/finalreport/download/${id}`
        return axiosClient.get(url)
    },

    // Lock/ Unlock
    lockBill: (id: string) => {
        const url = `/bill/lockBill/${id}`
        return axiosClient.put(url, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
    },
    unlockBill: (id: string) => {
        const url = `/bill/unlockbill/${id}`
        return axiosClient.put(url, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
    },

    // Overall Expense
    getExpenseYearly: (params: any) => {
        const url = '/bill/getexpenseyearly'
        return axiosClient.get(url, { params })
    },
    getExpenseTableYearly: (params: any) => {
        const url = '/bill/getexpensetableyearly'
        return axiosClient.get(url, { params })
    },
    getExpenseCodeTableYearly: (params: any) => {
        const url = '/bill/getexpensecodetableyearly'
        return axiosClient.get(url, { params })
    },
    getExpenseCodeDetail: (params: any) => {
        const url = '/bill/getexpensecodedetail'
        return axiosClient.get(url, { params })
    },
    getExpenseMonthlyPaymentMethod: (params: any) => {
        const url = '/bill/getexpensemonthlypaymentmethod'
        return axiosClient.get(url, { params })
    },

    // Monthly Expense
    getMonthlyExpenseStatistics: (params: any) => {
        const url = '/bill/getstatismonthly'
        return axiosClient.get(url, { params })
    },
    getMonthlyExpenseTable: (params: PayloadGetMonthlyExpenseTable) => {
        const url = '/bill/gettablemonthly'
        return axiosClient.get(url, { params })
    },
    updateBillStatus: params => {
        const url = '/bill/updatestatus'
        return axiosClient.put(url, params, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
    },

    // Payment Method
    getBillPaymentMethod: () => {
        const url = '/paymentinfo/getmethodbill'
        return axiosClient.get(url)
    },
    deleteBillPaymentMethod: (id: string) => {
        const url = `/paymentinfo/deletepaymentmethodbill/${id}`
        return axiosClient.delete(url)
    },
    postBillPaymentMethod: (data: string) => {
        const url = 'paymentinfo/createpaymentmethodbill?namePaymentMethod=' + encodeURIComponent(data)
        return axiosClient.post(url)
    },
}

export default billApi
