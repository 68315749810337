import axiosClient from './axiosClient'
import { payloadGetAuditOrdersAllFilter } from './types/order'
import { payloadPage } from './types/paramPage'

const auditApi = {
  getAllAudit: (params: payloadPage) => {
    const url = '/auditorder/getall'
    return axiosClient.get(url, { params })
  },

  getAuditsByUserId: (params: payloadPage) => {
    const url = '/auditorder/getallbyuserid'
    return axiosClient.get(url, { params })
  },

  getAuditsAllFilter: (params: payloadGetAuditOrdersAllFilter) => {
    const url = '/auditorder/getallfilter'
    return axiosClient.get(url, { params })
  },

  createAudit: params => {
    const url = '/auditorder/createaudit'
    return axiosClient.post(url, params, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  },

  updateAudit: params => {
    const url = `/auditorder/updateaudit/${params.id}`
    return axiosClient.put(url, params.body, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  },
  unlockAudit: (id: string) => {
    const url = `/auditorder/unlockauditorder/${id}`
    return axiosClient.put(url, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  },
  deleteAudit: (id: string) => {
    const url = `/auditorder/deleteaudit/${id}`
    return axiosClient.delete(url)
  },

  getAuditById: (id: string) => {
    const url = `/auditorder/getauditbyid/${id}`
    return axiosClient.get(url)
  },
  /** 
  downloadAttachmentId: (id: string) => {
    const url = `/attachment/download/${id}`
    return axiosClient.get(url)
  },
  downloadFinalReportById: (id: string) => {
    const url = `/finalreport/download/${id}`
    return axiosClient.get(url)
  },
  
  */
  updateStatus: (data: any) => {
    const url = `/auditorder/updatestatus`
    return axiosClient.put(url, data)
  },
  copyToDraft: (id: string) => {
    const url = `/auditorder/copytodraft?orderId=${id}`
    return axiosClient.post(url)
  },
  updateStatusFromDraftToUnfinish: (data: any) => {
    const url = `/auditorder/updatestatusfromdrafttounfinish`
    return axiosClient.put(url, data)
  },
}

export default auditApi;
